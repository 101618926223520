import React from "react";


export default class Api  {

	static URL='/api'
  //static URL='http://10.10.10.203:3003'

	static Saleables(text,page,branche){
      
      return fetch(Api.URL+"/saleables/list",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({search:text,page:page,branche:branche,token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }


  static onAutoComplete(text,branche){
      
      return fetch(Api.URL+"/autocomplete",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({search:text,branche:branche,token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }


  static CrateOrder(total,branche,detalles){
      
      return fetch(Api.URL+"/order/create",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({total:total,branche_id:branche,detalles:detalles,token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        Api.Quotations(data).then((data)=>{
          Api.SaveCache('quotes',data.data)
        })

        return data
      })
                
  }



 static SignUp(data){
      
      return fetch(Api.URL+"/singup",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(data)
      })
      .then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }

 static Login(data){
      
      return fetch(Api.URL+"/login",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(data)
      })
      .then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }

 static UserInfo(){
      
      return fetch(Api.URL+"/customers/info",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }
 static Branches(data){
      
      
      return fetch(Api.URL+"/branches/list",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({...data,token:localStorage.getItem('user_id')})
      }).then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }

  static SaveCache(key,data){

    var str = JSON.stringify(data)

    localStorage.setItem(key,str)
  }

  static GetCache(key){
    var str = localStorage.getItem(key)
    if(str==null){
      return null
    }else{
      return JSON.parse(str)
    }
  }

  static UserId(){
    return localStorage.getItem('user_id')
  }
 static AccountReportBranches(){
      
      
      return fetch(Api.URL+"/accountreport/branches",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({token:localStorage.getItem('user_id')})
      }).then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }
  static Invoices(data){
      
      return fetch(Api.URL+"/invoices/list",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({...data,token:localStorage.getItem('user_id')})
      }).then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }

  static PDF(url,data){
      
      return fetch(Api.URL+url,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({...data,token:localStorage.getItem('user_id')})
      }).then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }

  static Quotations(data){
      
      return fetch(Api.URL+"/quotation/list",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({...data,token:localStorage.getItem('user_id')})
      }).then((response) => response.json()).then((data) => {
        
        if(data.error!=undefined){
          alert(data.error)
        }
        return data
      })
                
  }


}