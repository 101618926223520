import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu,Icon} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

  

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          Menu
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <Menu.Item key="products">
                <Link to="/products"><Icon type="form" style={{fontSize:20}} />
                  <span>Crear Cotizacion</span></Link>
              </Menu.Item>

              
              <Menu.Item key="quotes">
                <Link to="/quotes"><Icon type="copy" style={{fontSize:20}} />
                  <span>Cotizaciones</span></Link>
              </Menu.Item>

              

              <Menu.Item key="invoices">
                <Link to="/invoices"><i className="icon icon-pricing-table"/>
                  <span>Facturas</span></Link>
              </Menu.Item>

              <Menu.Item key="accountreport">
                <Link to="/accountreport"><Icon type="folder" style={{fontSize:20}} />
                  <span>Estado de cuenta</span></Link>
              </Menu.Item>

              <Menu.Item key="account_customer_payment_report">
                <Link to="/account_customer_payment_report"><Icon type="dollar" style={{fontSize:20}} />
                  <span>Cobranza</span></Link>
              </Menu.Item>

              <Menu.Item key="user">
                <Link to="/user"><Icon type="user" style={{fontSize:20}} />
                  <span>Usuario</span></Link>
              </Menu.Item>

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

