import React from "react";
import {Route, Switch} from "react-router-dom";
import quotes from "./quotes";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      
      <Route path={`${match.url}products`} component={asyncComponent(() => import('./products'))}/>
      <Route path={`${match.url}quotes`} component={quotes}/>
      <Route path={`${match.url}invoices`} component={asyncComponent(() => import('./invoices'))}/>
      <Route path={`${match.url}user`} component={asyncComponent(() => import('./user'))}/>
      <Route path={`${match.url}accountreport`} component={asyncComponent(() => import('./accountreport'))}/>
      <Route path={`${match.url}account_customer_payment_report`} component={asyncComponent(() => import('./account_customer_payment_report'))}/>
    </Switch>
  </div>
);

export default App;
