import React from "react";
import {Table,Card,Input,Tabs,DatePicker,Row, Col,Button,Modal,Select,Badge,Icon,List, message, Avatar, Spin} from "antd";
import IntlMessages from "util/IntlMessages";
import Api from '../../Api'
import moment from 'moment';
import XMLParser from 'react-xml-parser';
import CurrencyFormat from 'react-currency-format';
const { Search } = Input;
const { TabPane } = Tabs;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { Option } = Select;

export default class Quotes extends React.Component {

  state = {cache_loading:true,loading:true,data:[],visible: false,factura:undefined,filter_one:false,branches:[],branches:[] }
  componentDidMount() {

    Api.Branches().then((data)=>{
      this.setState({branches:data.branches})
    })


    this.loadData({type:'all'})

  }

  loadData(data){
    
    Api.Quotations(data).then((data)=>{
      Api.SaveCache('quotes',data.data)
      this.setState({data:data.data,branches:data.branches,loading:false,cache_loading:false})
    })

    var quotes = Api.GetCache('quotes')
    if(quotes!=null){
      this.setState({data:quotes,loading:false})
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    
    this.setState({
      visible: false,
    });
  };

   handleCancel = e => {

    this.setState({
      visible: false,
    });
  };

  

series_number_filter(){
  this.setState({filter_one:true})
  this.loadData({type:'one',serie:this.state.serie,folio:this.state.folio,dates:null})

}
dateChange(date,dateString){

  if(date.length>0){

    this.setState({filter_one:true,dates:date});
    this.loadData({type:'date',date:date})
  }else{

    this.setState({filter_one:false,serie:'',folio:'',dates:null}); 

    this.loadData({type:'all'})
  }
}

branches(){

  var data = []
  data.push(<Option value={0}>Todas</Option>)
  for(var i in this.state.branches){
    var branche = this.state.branches[i]
    data.push(<Option value={branche.id}>{branche.name}</Option>)
  }
  return data
}

filter_branche_return_name(id){

  var list = this.state.branches.filter(b => { if(b.id==id){ return true }else{ return false } })
  
  if(list.length>0){
    return list[0].name;
  }
  return "";
}
expiration_date(s_date){

  if(Date.parse(s_date)>Date.now()){
    return (<><Badge status="success" />Vigente</>)
  }else{
    return (<><Badge status="error" />No Vigente</>)
  }
}
select_branch(id){
  if(id==0){
    this.loadData({type:'all'})
  }else{
    this.loadData({type:'branch',branch_id:id})
  }
  
}
render(){
  

  const columns = [
    {
      title: 'Folio',
      dataIndex: 'number',
      key: 'number',
    },{
      title: 'Sucursal',
      dataIndex: 'branch_id',
      key: 'branch_id',
      render: (text,row) =>{ return this.filter_branche_return_name(text) },
    },{
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text =>{ if(text==undefined){ return "" } return moment(text).format('YYYY/MM/DD'); },
    },
    {
      title: 'Total USD',
      dataIndex: 'usd_total',
      key: 'usd_total',
      render: text=>{ return (<CurrencyFormat value={text} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />)  }
    },{
      title: 'Total MXN',
      dataIndex: 'mxn_total',
      key: 'mxn_total',
      render: text=>{ return (<CurrencyFormat value={text} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />)  }
    },
    {
      title: 'Accion',
      dataIndex: 'id',
      key: 'id',
      render: (text,row) =>{ return (<><Button onClick={()=>{ Api.PDF("/quotation/pdf/"+text).then((data)=>{ this.setState({visible:true,show_id: text,pdf_data:data.base64}) })  }} style={{marginBottom:0}} size="small" icon="search">Ver</Button> </>); },
    }


    
  ];

    return (
      <div>
        <h2 className="title gx-mb-4">Cotizaciones {this.state.cache_loading && (<Spin indicator={<Icon type="loading" style={{ fontSize: 30 }} spin />} />)}</h2>
        

        

           
        <div className="gx-d-flex justify-content-center">
          <Card style={{width:'100%'}} bordered={true}>
            <Row>
              <Col xs={0} sm={0} md={24} lg={24} xl={24}>
              {this.state.loading && (
                    <div style={{margin: 'auto',width:100,padding: 10}}>
                      <Spin />
                    </div>
              )}
              {this.state.loading==false && (
                <Table size="small"  dataSource={this.state.data} columns={columns} />
              
              )}
              </Col>
              <Col xs={24} sm={24} md={0} lg={0} xl={0} style={{margin:0,padding:0}}>

                <List
                  dataSource={this.state.data}
                  renderItem={item => {


                    return (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        title={<><Icon type="number" /> {item.number} - <Icon type="calendar" /> {moment(item.created_at).format('YYYY/MM/DD')} - <Icon type="dollar" /> <CurrencyFormat value={item.total} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} /></>}
                        description={<><Icon type="home" />  {this.filter_branche_return_name(item.branch_id)} </>}
                      />
                      <div>
                        <Button onClick={()=>{ this.setState({visible:true,show_id: item.id}) }} style={{marginBottom:0}} size="small" icon="search">Ver</Button>
                      </div>
                    </List.Item>
                  )}}
                >
                  {this.state.loading && (
                    <div style={{margin: 'auto',width:50,padding: 10}}>
                      <Spin />
                    </div>
                  )}
                </List>
              </Col>
            </Row>
          </Card>
        </div>


        <Modal
          width={"80%"}
          
          title="Detalles"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
        <div style={{height:900}}>
          <iframe  width="100%" height="100%" frameBorder="0" src={'data:application/pdf;base64,'+this.state.pdf_data}></iframe>
        </div>
        </Modal>



      </div>
    );
  }
}
